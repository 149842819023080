import Layout from '../components/layout';
import React from 'react';
import SEO from '../components/seo';
import majorityImage from '../../content/assets/projects/majority.gif';
import adventuresImage from '../../content/assets/projects/adventures.gif';
import rushhourImage from '../../content/assets/projects/rushhour.png';
import zookeeperImage from '../../content/assets/projects/zoo.png';
import dotedbImage from '../../content/assets/projects/dotedb.png';
import phiImage from '../../content/assets/projects/phi.png';
import swordsAndRavensImage from '../../content/assets/projects/swordsandravens.jpg';
import * as projectsStyles from './projects.module.css';
import { rhythm } from '../utils/typography';

const projects = [
    {
        name: 'Swords and Ravens',
        description: (
            <p>
                <i>Swords and Ravens</i> is an online adaptation of the board
                game <i>A Game of Thrones: The Board Game</i>, edited by{' '}
                <a href='https://www.fantasyflightgames.com/en/index/'>FFG</a>.
            </p>
        ),
        link: 'https://swordsandravens.net/',
        image: swordsAndRavensImage,
        github: 'https://swordsandravens.net/',
    },
    {
        name: 'Majority',
        description: (
            <p>
                <i>Majority</i> is a multiplayer game where the players try to
                guess which answer will be picked by the majority of the
                players.
            </p>
        ),
        link: 'https://majority.longwelwind.net/',
        image: majorityImage,
        github: 'https://github.com/Longwelwind/majority',
    },
];
const discontinuedProjects = [
    {
        name: 'Phi',
        description: (
            <>
                <p>
                    Phi is a mod for the game{' '}
                    <a href='http://rimworldgame.com/' target='_blank'>
                        Rimworld
                    </a>{' '}
                    (made by{' '}
                    <a href='https://ludeon.com/blog/' target='_blank'>
                        Ludeon Studios
                    </a>
                    ) that enables multiplayer interactions between players.
                </p>
                <p>
                    <b>Note</b>: At its peak, the mod had 17.000 subscribers on
                    Steam Workshop. The project was then discontinued but was
                    eventually revived by members to the community under the
                    name{' '}
                    <a
                        href='https://github.com/PhinixTeam/Phinix'
                        target='_blank'
                    >
                        Phinix
                    </a>
                    .
                </p>
            </>
        ),
        image: phiImage,
        github: 'https://github.com/Longwelwind/Phi',
    },
    {
        name: 'Adventures',
        description: (
            <p>
                <i>Adventures</i> is a plugin for{' '}
                <a href='https://twinery.org/'>Twine</a> that allows player to
                write interactive stories with RPG elements such as health,
                gold, items, stats and more.
            </p>
        ),
        link: 'https://longwelwind.github.io/adventures/',
        image: adventuresImage,
        github: 'https://github.com/Longwelwind/adventures',
    },
];

export default ({ location }) => {
    function renderProject(p) {
        return (
            <div>
                <h3 style={{ marginTop: rhythm(1) }}>
                    {p.link ? (
                        <a href={p.link} style={{color: "inherit"}}>{p.name}</a>
                    ) : (
                        p.name
                    )}   
                </h3>
                <a href={p.link}>
                    <div
                        className={projectsStyles.projectLogo}
                        style={{ backgroundImage: `url(${p.image})` }}
                    />
                </a>
                {p.description}
                <ul className={projectsStyles.metadataList}>
                    {p.github && (
                        <li>
                            <strong>Repository</strong>:{' '}
                            <a href={p.github} target='_blank'>
                                GitHub
                            </a>
                        </li>
                    )}
                </ul>
            </div>
        );
    }

    return (
        <Layout location={location} maxi={false}>
            <SEO title='Projects' description='Projects' />
            <h1 style={{ marginTop: rhythm(2), marginBottom: rhythm(0.5) }}>
                Projects
            </h1>
            <div className={projectsStyles.projectsContainer}>
                {projects.map(p => renderProject(p))}
            </div>
            <h2 style={{ marginBottom: rhythm(0.5) }}>Discontinued Projects</h2>
            <div className={projectsStyles.projectsContainer}>
                {discontinuedProjects.map(p => renderProject(p))}
            </div>
        </Layout>
    );
};
