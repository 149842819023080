import React from 'react';
import { Link, graphql } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { rhythm, scale } from '../utils/typography';

const BlogPostTemplate = ({ data, pageContext, location }) => {
    const post = data.mdx;
    const siteTitle = data.site.siteMetadata.title;
    const { previous, next } = pageContext;

    return (
        <Layout location={location} maxi={false}>
            <SEO
                title={post.frontmatter.title}
                description={post.frontmatter.description || post.excerpt}
            />
            <article>
                <header style={{marginBottom: rhythm(1)}}>
                    <h1
                        style={{
                            marginTop: rhythm(2),
                            marginBottom: 0,
                            color: "var(--accent)"
                        }}
                    >
                        {post.frontmatter.title}
                    </h1>
                    <small style={{...scale(-1 / 5), marginRight: rhythm(1 / 2)}}>{post.frontmatter.date}</small>
                    <small>{post.fields.readingTime.text}</small>
                </header>
                <MDXRenderer>{post.body}</MDXRenderer>
            </article>
        </Layout>
    );
};

export default BlogPostTemplate;

export const pageQuery = graphql`
    query BlogPostBySlug($slug: String!) {
        site {
            siteMetadata {
                title
            }
        }
        mdx(fields: { slug: { eq: $slug } }) {
            id
            excerpt(pruneLength: 160)
            body
            frontmatter {
                title
                date(formatString: "MMMM DD, YYYY")
            }
            fields {
                readingTime {
                    text
                }
            }
        }
    }
`;
