import Module from './Module';
import { perlinNoise, randomFloat } from '../utils/random';

export default class ScreenShake extends Module {
    readonly SHAKE_DURATION = 0.3;
    readonly ANGLE_PERIOD = 0.01;
    readonly DISTANCE_PERIOD = 0.5;
    readonly SHAKE_DISTANCE = 7;

    timeRemaining: number;
    i: number = 0;

    getName(): string {
        return 'Screen Shake';
    }

    onBlockBreak(): void {
        this.timeRemaining = this.SHAKE_DURATION;
        this.i++;
    }

    drawAfterBackground(ctx: CanvasRenderingContext2D, delta: number): void {
        this.timeRemaining -= delta;
        this.timeRemaining = Math.max(0, this.timeRemaining);
        let timeElapsed = this.SHAKE_DURATION - this.timeRemaining;
        let advancement = timeElapsed / this.SHAKE_DURATION;

        // The first argument of `perlinNoise` is the horizontal
        // axis of our noise. Here, it represents time. Indeed, the angle
        // moves with time and will follow a smooth motion.
        // Since `perlinNoise` is deterministic, it would produce the
        // same shake every time. So we add
        // `this.i * this.ANGLE_PERIOD` to make the shake different
        // each time.

        let angle =
            perlinNoise(
                this.i * this.ANGLE_PERIOD + timeElapsed,
                this.ANGLE_PERIOD
            ) +
            advancement * Math.PI * 2;

        let getGradient = seed => {
            let rand = randomFloat(seed, -0.5, 0.5);
            return rand > 0 ? rand + 0.5 : rand - 0.5;
        };
        let distance_amplitude = this.SHAKE_DISTANCE * (1 - advancement);
        let distance =
            perlinNoise(
                this.i * this.DISTANCE_PERIOD + timeElapsed,
                this.DISTANCE_PERIOD,
                getGradient
            ) * distance_amplitude;

        ctx.setTransform(
            1,
            0,
            0,
            1,
            Math.cos(angle) * distance,
            Math.sin(angle) * distance
        );
    }
}
