import Layout from '../components/layout';
import React from 'react';

export default ({ location }) => {
    return (
        <Layout location={location} maxi={false}>
            Test
        </Layout>
    );
};
