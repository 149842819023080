import { Component, default as React, ReactNode } from 'react';
import ModuleControls from './ModuleControls';
import Game from './Game';

export default class JuiceIt extends Component {
    // Singleton of Game
    _game: Game;
    get game() {
        if (this._game == null) {
            this._game = new Game();
        }

        return this._game;
    }

    render(): ReactNode {
        return (
            <p>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <div
                        className='bordered-box'
                        style={{ maxWidth: '502px', padding: 0 }}
                    >
                        <canvas
                            id='game-canvas'
                            style={{ width: '100%', marginBottom: '-6px' }}
                            ref='canvas'
                        />
                    </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <ModuleControls game={this.game} />
                </div>
            </p>
        );
    }

    componentDidMount(): void {
        const canvasElement = this.refs.canvas as HTMLCanvasElement;
        let ctx = canvasElement.getContext('2d');

        let canvasSize = this.game.getCanvasSize();
        canvasElement.width = canvasSize.x;
        canvasElement.height = canvasSize.y;

        const game = this.game;

        // Setup the events on the canvas
        canvasElement.addEventListener('pointermove', e => {
            handleEvent(e.pageX, e.pageY);
        });

        function handleEvent(x: number, y: number) {
            let pos = {
                x: x - canvasElement.offsetLeft,
                y: y - canvasElement.offsetTop,
            };
            // Depending on the scale of the canvas, the input
            // may be skewed.
            // Fix that
            let ratio = game.getCanvasSize().x / canvasElement.offsetWidth;
            pos.x *= ratio;
            pos.y *= ratio;
            game.handleMouseMove(pos);
        }

        // Launch the update loop
        let lastUpdate = Date.now();
        function loop() {
            // Adapt the size of the canvas so that the ratio is always the same
            let canvasSize = game.getCanvasSize();
            let ratio = canvasSize.x / canvasSize.y;
            canvasElement.style.height =
                Math.round(canvasElement.offsetWidth / ratio) + 'px';

            // Update the game
            let delta = Date.now() - lastUpdate;
            // Limit the delta to avoid huge deltas
            delta = Math.min(delta, 100) / 1000;
            game.update(delta);
            lastUpdate = Date.now();

            // Draw the game
            game.draw(ctx, delta);

            // Ask for a next frame
            requestAnimationFrame(() => loop());
        }
        loop();
    }
}
