import NetworkDiagram from "../../../../content/blog/networking-turn-based-game/images/network-diagram.drawio.svg";
import CommDiagram from "../../../../content/blog/networking-turn-based-game/images/comm-diagram.drawio.svg";
import CommDeltaDiagram from "../../../../content/blog/networking-turn-based-game/images/comm-delta-diagram.drawio.svg";
import CommDeterministicDiagram from "../../../../content/blog/networking-turn-based-game/images/comm-deterministic-diagram.drawio.svg";
import CommSecretStateDiagram from "../../../../content/blog/networking-turn-based-game/images/comm-secret-state.drawio.svg";
import * as React from 'react';
export default {
  NetworkDiagram,
  CommDiagram,
  CommDeltaDiagram,
  CommDeterministicDiagram,
  CommSecretStateDiagram,
  React
};