import Module from './Module';
import ColorModule from './ColorModule';
import Game from '../Game';
import { BLUE_1, BLUE_2, PURPLE_1, PURPLE_3, PURPLE_2 } from '../Color';
import { toHexString } from '../utils/hex';

export default class FunkyBackground extends Module {
    readonly COUNT_SECTIONS = 10;
    readonly DISTANCE = 2000; // Just to be sure
    readonly SPEED_ANGLE = Math.PI / 12;
    readonly COLORS = [PURPLE_3, PURPLE_2];

    currentStartingAngle = 0;
    dependencies = [ColorModule];

    drawAfterBackground(ctx: CanvasRenderingContext2D, delta: number): void {
        this.currentStartingAngle += this.SPEED_ANGLE * delta;

        let center = {
            x: Game.BORDER_SIZE + Game.ARENA_SIZE.x / 2,
            y: Game.BORDER_SIZE + Game.ARENA_SIZE.y / 2,
        };

        let currentAngle = this.currentStartingAngle;
        let colorIndex = 0;
        for (let i = 0; i < this.COUNT_SECTIONS; i++) {
            // Draw one section
            ctx.beginPath();
            ctx.lineTo(center.x, center.y);
            ctx.lineTo(
                center.x + Math.cos(currentAngle) * this.DISTANCE,
                center.y + Math.sin(currentAngle) * this.DISTANCE
            );
            currentAngle += (2 * Math.PI) / this.COUNT_SECTIONS;
            ctx.lineTo(
                center.x + Math.cos(currentAngle) * this.DISTANCE,
                center.y + Math.sin(currentAngle) * this.DISTANCE
            );
            ctx.closePath();

            ctx.fillStyle = toHexString(this.COLORS[colorIndex], 1);
            ctx.fill();

            colorIndex = (colorIndex + 1) % this.COLORS.length;
        }
    }

    getName() {
        return 'Funky Background';
    }
}
