export interface Color {
	r: number;
	g: number;
	b: number;
}

export let BLACK = { r: 0, g: 0, b: 0 };

// Follow the palette Super Plum 21
export let PURPLE_2 = {r: 77, g: 31, b: 77};
export let PURPLE_1 = {r: 166, g: 58, b: 130};
export let BROWN = {r: 166, g: 55, b: 55};
export let BLUE_2 = {r: 21, g: 120, b: 140};
export let BLUE_1 = {r: 38, g: 110, b: 255};
export let BLUE_3 = {r: 40, g: 59, b: 115};
export let PURPLE_3 = {r: 52, g: 31, b: 52};

export let BEIGE_2 = {r: 255, g: 188, b: 166};
export let RED = {r: 255, g: 89, b: 131};
export let ORANGE = {r: 243, g: 124, b: 83};
export let GREEN_1 = {r: 20, g: 204, b: 128};

export let WHITE = { r: 255, g: 255, b: 255 };
export let BEIGE_1 = {r: 255, g: 238, b: 234};
export let YELLOW = {r: 255, g: 195, b: 77};
export let GREEN_2 = {r: 203, g: 229, b: 69};
