
import { Color, WHITE } from "../Color";
import Game from "../Game";
import { observable } from "mobx";

export default class Module {
	game: Game;
	dependencies: typeof Module[] = [];
	@observable enabled: boolean = false;

	constructor(game: Game) {
		this.game = game;
	}

	getName(): string { return "Simple module"; }
	isAudio(): boolean { return false; }
	onEnable(): void { return null; }
	onDisable(): void { return null; }

	onBlockBreak(block: Point, ballAngle: number): void { }
	getBlockColor(block: Point): Color { return null; }
	getBlockScale(block: Point): number { return null; }
	getBorderColor(): Color { return null; }

	getPaddleColor(): Color { return null; }
	getPaddleScale(): number { return null; }
	
	onBallPaddleCollision(): void {  }
	getBallColor(): Color { return null; }
	getBallScale(): number { return null; }

	getBackgroundColor(): Color { return null; }
	
	drawAfterBackground(ctx: CanvasRenderingContext2D, delta: number): void { }
	drawBeforeElements(ctx: CanvasRenderingContext2D, delta: number): void { }

	render(): JSX.Element { return null; }

	toggleEnable(): void {
		if (!this.enabled && !this.canEnable()) {
			return;
		}
 
		this.enabled = !this.enabled;
		if (this.enabled) {
			this.onEnable();
		} else {
			this.onDisable();

			// Disable dependencies modules
			this.game.modules
				.filter(m => m.enabled)
				.filter(dm => dm.dependencies.some(d => this instanceof d)).forEach(m => {
					m.toggleEnable();
				});
		}
	}

    canEnable(): boolean {
        return this.dependencies.every(d => this.game.getModule(d).enabled);
    }
}