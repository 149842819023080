import { Color } from "../Color";

export function toHex(num: number): string {
    return ("00" + num.toString(16)).substr(-2);
}

export function toHexString(color: Color, alpha: number = 1): string {
    return "#" + toHex(color.r)
    + toHex(color.g)
    + toHex(color.b)
    + toHex(Math.floor(alpha * 255));;
}