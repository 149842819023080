import { Component, ReactNode } from 'react';
import * as React from 'react';
import { rhythm } from '../../../../src/utils/typography';
import { drawToCanvas, getOctavedPerlinNoiseGrid } from './library';

interface PerlinNoiseProps {
    period: number;
    minPeriod: number;
    maxPeriod: number;
    minOctaves: number;
    maxOctaves: number;
    displaySmoothOption: boolean;
    smoothed: boolean;
    displayOverlayOption: boolean;
}

interface PerlinNoiseState {
    seed: number;
    period: number;
    octaves: number;
    attenuation: number;
    smoothed: boolean;
    overlay: boolean;
}

export default class PerlinNoise extends Component<
    PerlinNoiseProps,
    PerlinNoiseState
> {
    state = {
        seed: 16180,
        period: this.props.period,
        octaves: 1,
        attenuation: 0.5,
        smoothed: this.props.smoothed,
        overlay: this.props.displayOverlayOption,
    };

    render(): ReactNode {
        return (
            <p style={{ display: 'flex' }}>
                <div style={{ marginRight: rhythm(1 / 4)}}>
                    <div>
                        <b>Seed:</b> <br />
                        <input
                            type='text'
                            value={this.state.seed}
                            onChange={e =>
                                this.setState({
                                    seed: parseInt(e.target.value),
                                })
                            }
                        />
                    </div>
                    <div>
                        <b>Start Period:</b> {this.state.period}
                        <br />
                        <input
                            type='range'
                            min={this.props.minPeriod}
                            max={this.props.maxPeriod}
                            value={this.state.period}
                            onChange={e =>
                                this.setState({
                                    period: parseInt(e.target.value),
                                })
                            }
                        />
                    </div>
                    {this.props.maxOctaves > 1 && (
                        <>
                            <div>
                                <b>Octaves:</b> {this.state.octaves}
                                <br />
                                <input
                                    type='range'
                                    min={this.props.minOctaves}
                                    max={this.props.maxOctaves}
                                    value={this.state.octaves}
                                    onChange={e =>
                                        this.setState({
                                            octaves: parseInt(e.target.value),
                                        })
                                    }
                                />
                            </div>
                            <div>
                                <b>Attenuation:</b>
                                <br />
                                <input
                                    type='range'
                                    min={0}
                                    max={1}
                                    value={this.state.attenuation}
                                    step={0.1}
                                    onChange={e =>
                                        this.setState({
                                            attenuation: parseFloat(
                                                e.target.value
                                            ),
                                        })
                                    }
                                />

                                <input
                                    value={this.state.attenuation}
                                    onChange={e =>
                                        this.setState({
                                            attenuation: parseFloat(
                                                e.target.value
                                            ),
                                        })
                                    }
                                />
                            </div>
                        </>
                    )}
                    {this.props.displaySmoothOption && (
                        <div>
                            <b>Smoothed (fade):</b>
                            <input
                                type='checkbox'
                                checked={this.state.smoothed}
                                onChange={e =>
                                    this.setState({
                                        smoothed: !this.state.smoothed,
                                    })
                                }
                            />
                        </div>
                    )}
                    {this.props.displayOverlayOption && (
                        <div>
                            <b>Overlay:</b>
                            <input
                                type='checkbox'
                                checked={this.state.overlay}
                                onChange={e =>
                                    this.setState({
                                        overlay: !this.state.overlay,
                                    })
                                }
                            />
                        </div>
                    )}
                </div>
                <div>
                    <canvas width='512' height='512' ref='canvas' />
                </div>
            </p>
        );
    }

    updateCanvas() {
        const {
            seed,
            period,
            octaves,
            attenuation,
            smoothed,
            overlay,
        } = this.state;

        const { grid, perlinVectors } = getOctavedPerlinNoiseGrid(
            seed,
            512,
            512,
            period,
            octaves,
            attenuation,
            smoothed
        );

        drawToCanvas(
            grid,
            this.refs.canvas,
            period,
            overlay ? perlinVectors[0] : null
        );
    }

    componentDidMount(): void {
        setTimeout(() => {
            this.updateCanvas();
        }, 0);
    }

    componentDidUpdate(): void {
        this.updateCanvas();
    }
}
