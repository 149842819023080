import { rhythm } from '../utils/typography';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './footer.module.css';   
import { config } from '@fortawesome/fontawesome-svg-core';
import '@fortawesome/fontawesome-svg-core/styles.css';
import { faGithub } from '@fortawesome/free-brands-svg-icons/faGithub';
import { faTwitter } from '@fortawesome/free-brands-svg-icons/faTwitter';
import { faRss } from '@fortawesome/free-solid-svg-icons/faRss';

config.autoAddCss = false;

const socialMedias = [
    {
        icon: faTwitter,
        link: 'https://twitter.com/Longwelwind',
        target: '_blank',
    },
    {
        icon: faGithub,
        link: 'https://github.com/Longwelwind',
        target: '_blank',
    },
    //{ icon: faRss, link: '/rss.xml' },
];

const Footer = () => {
    return (
        <footer>
            {socialMedias.map(sm => (
                <a href={sm.link} target={sm.target} key={sm.link}>
                    <FontAwesomeIcon icon={sm.icon} size='2x' />
                </a>
            ))}
        </footer>
    );
};

export default Footer;
