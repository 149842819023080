import Typography from 'typography';
import "@fontsource/open-sans/400.css";
import "@fontsource/open-sans/700.css";
import {
    MOBILE_MEDIA_QUERY,
    TABLET_MEDIA_QUERY,
} from "typography-breakpoint-constants"

const typography = new Typography({
    baseFontSize: "17px",
    baseLineHeight: 1.78,
    bodyFontFamily: ["Open Sans"],
    bodyColor: "white",
    bodyWeight: 300,
    headerFontFamily: ["Open Sans"],
    headerWeight: 800,
    overrideStyles: ({ adjustFontSizeTo, scale, rhythm }, options) => ({
        'h1 a, h2 a, h3 a': {
            textDecoration: 'none',
        },
        a: {
            color: "var(--accent)",
            textDecoration: "none",
        },
        "a:hover,a:active": {
            textDecoration: "underline",
        },
        ul: {
            listStyle: "disc",
        },
        "ul,ol": {
            marginLeft: 0,
        },
        [MOBILE_MEDIA_QUERY]: {
            "ul,ol": {
                marginLeft: rhythm(1),
            },
            blockquote: {
                marginLeft: rhythm(-3 / 4),
                marginRight: 0,
                paddingLeft: rhythm(9 / 16),
            },
        },
        [TABLET_MEDIA_QUERY]: {
            h1: {
                ...scale(5 / 5),
            },
        },
        "h1,h2,h3,h4,h5,h6": {
            marginTop: rhythm(2),
        },
        h1: {
            ...scale(6 / 5),
            letterSpacing: "-2px",
        },
        h6: {
            fontStyle: "italic",
        },
        code: {
            color: "var(--accent)",
            padding: "1px 6px",
        },
        ".gatsby-highlight": {
            marginBottom: rhythm(1),
            marginLeft: "-1.3125rem",
            marginRight: "-1.3125rem",
            fontSize: "0.85rem",
        },
        ".gatsby-highlight pre[class*=\"language-\"]": {
            padding: "1.4rem 1.3125rem",
        },
        small: {
            color: "#b3b3b3"
        },
        svg: {
            display: "block",
            marginLeft: "auto",
            marginRight: "auto",
            maxWidth: "100%",
            marginBottom: rhythm(1),
        },
        figcaption: {
            ...scale(-0.2),
            color: "#b3b3b3",
            textAlign: "center",
            marginTop: rhythm(0.1),
        },
        ".tight-row": {
            maxWidth: rhythm(26),
        },
        ".extended-row": {
            maxWidth: rhythm(30),
        },
        "hr": {
            backgroundColor: "#b3b3b3",
        },
        ".footnote-backref": {
            marginLeft: rhythm(0.1),
        },
    })
});

// Hot reload typography in development.
if (process.env.NODE_ENV !== `production`) {
    typography.injectStyles();
}

export default typography;
export const rhythm = typography.rhythm;
export const scale = typography.scale;
