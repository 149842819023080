
import Module from "./Module";
import Game from "../Game";
import { WHITE } from "../Color";
import { easeOut, easeIn } from "../utils/easing";

interface BlockBreakAnimation {
    block: Point,
    particleStartPoint: Point,
    timeElapsed: number,
    particles: BlockBreakParticle[]
}

interface BlockBreakParticle {
    angle: number,
    distance: number
    scale: number
}

export default class BlockParticles extends Module {
    readonly ANIMATION_DURATION = 0.15;
    readonly PARTICLE_DURATION = 1;
    readonly PARTICLE_COUNT = 25;
    readonly PARTICLE_SIZE = 4;
    readonly PARTICLE_SCALE_MAX = 1.3;
    readonly PARTICLE_DISTANCE_MIN = 10;
    readonly PARTICLE_DISTANCE_RANGE = 40;
    readonly PARTICLE_ANGLE_OPENING = Math.PI / 2;
    animations: BlockBreakAnimation[] = [];

    getName(): string {
        return "Block Particles";
    }

    onEnable(): void {
        this.animations = [];
    }
    
    onBlockBreak(block: Point, ballAngle: number): void {
        // Generate a swarm of particles
        let particles: BlockBreakParticle[] = [];
        for (let i =0;i < this.PARTICLE_COUNT;i++) {
            particles.push({
                angle: Math.random() * Math.PI * 2,
                distance: this.PARTICLE_DISTANCE_MIN
                    + this.PARTICLE_DISTANCE_RANGE * Math.random(),
                scale: 1 + (this.PARTICLE_SCALE_MAX - 1) * Math.random()
            });
        }

        // A small pre-computation that will be used to draw
        // particles
        let particleStartPoint = {
            x: block.x + Game.BLOCK_SIZE.x / 2 - this.PARTICLE_SIZE / 2,
            y: block.y + Game.BLOCK_SIZE.y / 2 - this.PARTICLE_SIZE / 2
        };

        this.animations.push({
            block,
            timeElapsed: 0,
            particles,
            particleStartPoint
        });
    }

    drawBeforeElements(ctx: CanvasRenderingContext2D, delta: number): void {
        this.animations.forEach(a => {
            a.timeElapsed += delta;

            // We assume that the particles lasts longer than the
            // shrinking animation
            if (this.PARTICLE_DURATION < this.ANIMATION_DURATION) {
                throw new Error(
                    "Particle duration should be bigger than animation duration"
                );
            }
            if (a.timeElapsed > this.PARTICLE_DURATION) {
                this.animations.splice(this.animations.indexOf(a), 1);
                return;
            }

            let colorBlock = this.game.callModules(m => m.getBlockColor(a.block), WHITE);

            // Draw the particles
            let particleAdvancement = a.timeElapsed / this.PARTICLE_DURATION;
            particleAdvancement = easeOut(particleAdvancement);
            a.particles.forEach(p => {
                let distance = particleAdvancement * p.distance;
                let opacity = 1 - particleAdvancement;
                let pos = {
                    x: Game.BORDER_SIZE
                        + a.particleStartPoint.x + Math.cos(p.angle) * distance,
                    y: Game.BORDER_SIZE
                        + a.particleStartPoint.y + Math.sin(p.angle) * distance
                };

                this.game.rect(ctx, pos, {
                        x: this.PARTICLE_SIZE, y: this.PARTICLE_SIZE
                    },
                    colorBlock,
                    p.scale,
                    opacity
                );
            });

            // Draw the block shrinking
            let animationAdvancement = a.timeElapsed / this.ANIMATION_DURATION;
            let scale = 1 - easeIn(animationAdvancement);
            if (animationAdvancement <= 1) {
                this.game.rect(
                    ctx,
                    {
                        x: Game.BORDER_SIZE + a.block.x,
                        y: Game.BORDER_SIZE + a.block.y
                    },
                    Game.BLOCK_SIZE,
                    colorBlock,
                    scale
                );
            }
        });
    }
}