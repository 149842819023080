import Module from './Module';
import * as React from 'react';
import {
    Color,
    RED,
    GREEN_1,
    BLUE_2,
    PURPLE_3,
    YELLOW,
    ORANGE,
    PURPLE_2,
    GREEN_2,
    WHITE,
    BEIGE_2,
    BLUE_1,
    BLUE_3,
    BROWN,
    PURPLE_1,
} from '../Color';
import { random } from '../utils/random';

export default class ColorModule extends Module {
    colors = [RED, GREEN_1, GREEN_2, BLUE_1, YELLOW, ORANGE];

    getName(): string {
        return 'Color';
    }

    getBackgroundColor(): Color {
        return PURPLE_3;
    }

    getBorderColor(): Color {
        return PURPLE_1;
    }

    getBallColor(): Color {
        return WHITE;
    }

    getPaddleColor(): Color {
        return WHITE;
    }

    getBlockColor(b: Point): Color {
        let seed = b.y;
        // `random` will always return the same value for the same
        // seed, that is, for the same block.
        return this.colors[random(seed, 0, this.colors.length)];
    }

    render(): JSX.Element {
        return (
            <div>
                <small>
                    <i>
                        <a
                            href='https://lospec.com/palette-list/super-plum-21'
                            target='_blank'
                        >
                            Super plum 21
                        </a>{' '}
                        by{' '}
                        <a
                            href='https://www.reddit.com/user/bman4750'
                            target='_blank'
                        >
                            /r/bman4750
                        </a>
                    </i>
                </small>
            </div>
        );
    }
}
