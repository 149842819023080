import React, { useEffect } from 'react';
import { Link, graphql } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';
import { rhythm } from '../utils/typography';

const BlogIndex = ({ data, location }) => {
    const siteTitle = data.site.siteMetadata.title;
    const posts = data.allMdx.edges;

    return (
        <Layout location={location} maxi={true}>
            <SEO />
            {posts.filter(({ node }) => process.env.NODE_ENV == "development" || !node.frontmatter.draft).map(({ node }) => {
                const title = node.frontmatter.title || node.fields.slug;
                return (
                    <div key={node.fields.slug} style={{marginBottom: rhythm(2.5)}}>
                        <header style={{marginBottom: rhythm(1 / 2)}}>
                            <h3
                                style={{
                                    marginBottom: rhythm(1 / 6),
                                }}
                            >
                                <Link
                                    style={{ boxShadow: `none` }}
                                    to={'/blog' + node.fields.slug}
                                >
                                    {title}
                                </Link>
                            </h3>
                            <small style={{marginRight: rhythm(1 / 2)}}>{node.frontmatter.date}</small>
                            <small>{node.fields.readingTime.text}</small>
                        </header>
                        <section>
                            <p
                                dangerouslySetInnerHTML={{
                                    __html: node.excerpt,
                                }}
                            />
                        </section>
                    </div>
                );
            })}
        </Layout>
    );
};

export default BlogIndex;

export const pageQuery = graphql`
    query {
        site {
            siteMetadata {
                title
            }
        }
        allMdx(sort: { fields: [frontmatter___date], order: DESC }) {
            edges {
                node {
                    excerpt
                    fields {
                        slug
                        readingTime {
                            text
                        }
                    }
                    frontmatter {
                        date(formatString: "MMMM D, YYYY")
                        title
                        draft
                    }
                }
            }
        }
    }
`;
