
import Module from "./modules/Module";
import { Color, WHITE, BLACK } from "./Color";
import ColorModule from "./modules/ColorModule";
import MusicModule from "./modules/MusicModule";
import RhythmModule from "./modules/RhythmModule";
import BallParticlesModule from "./modules/BallParticlesModule";
import BlockParticles from "./modules/BlockParticlesModule";
import { toHex, toHexString } from "./utils/hex";
import FunkyBackground from "./modules/FunkyBackground";
import ScreenShake from "./modules/ScreenShake";
import { Sound } from "./modules/Sound";
import { PaddleTrail } from "./modules/PaddleTrail";

export default class Game {
	static readonly ARENA_SIZE = { x: 480, y: 500 };
	static readonly BORDER_SIZE = 10;
	static readonly PADDLE_SIZE = { x: 80, y: 10 };
	static readonly BALL_SIZE = { x: 10, y: 10 };
	static readonly BALL_SPEED = 240;

	static readonly BLOCK_SIZE = { x: 30, y: 15 };
	static readonly BLOCK_GRID_OFFSET = { x: 80, y: 40 };
	static readonly BLOCK_COUNT = { x: 11, y: 8 };

	blocks: Point[] = [];

	ball: Point;
	ballAngle: number;

	paddleX: number = 0;

	modules: Module[];

	constructor() {
		this.reset();

		// Instantiate modules
		this.modules = [
			new ColorModule(this), new BallParticlesModule(this),
			new BlockParticles(this), new FunkyBackground(this),
			new ScreenShake(this)
		];
	}

	reset() {
		this.ball = { x: 240, y: 350 };
		this.ballAngle = -Math.PI / 4;

		// Init blocks
		this.blocks = [];

		let space_between_blocks = (Game.ARENA_SIZE.x - Game.BLOCK_GRID_OFFSET.x - Game.BLOCK_SIZE.x * (Game.BLOCK_COUNT.x))
			/ (Game.BLOCK_COUNT.x - 1);

		for (let i = 0; i < Game.BLOCK_COUNT.y; i++) {
			for (let j = 0; j < Game.BLOCK_COUNT.x; j++) {
				this.blocks.push({
					x: Game.BLOCK_GRID_OFFSET.x / 2 + j * (Game.BLOCK_SIZE.x + space_between_blocks),
					y: Game.BLOCK_GRID_OFFSET.y + i * (Game.BLOCK_SIZE.y + space_between_blocks)
				});
			}
		}
	}

	update(delta: number): void {
		// Move the ball
		// 0 is for x dimension
		// 1 is for y dimension
		// I do that to reuse the collision code
		[0, 1].forEach(direction => {
			let collision = false;
			let movement = (direction == 0 ? Math.cos(this.ballAngle) : Math.sin(this.ballAngle))
				* Game.BALL_SPEED * delta;
			let newBallPosition = {
				x: this.ball.x + (direction == 0 ? movement : 0),
				y: this.ball.y + (direction == 0 ? 0 : movement)
			};

			// We check collision with the 4 corners of the ball
			[{ x: 0, y: 0 }, { x: 1, y: 0 }, { x: 0, y: 1 }, { x: 1, y: 1 }]
				.map(a => {
					return { x: newBallPosition.x + a.x * Game.BALL_SIZE.x, y: newBallPosition.y + a.y * Game.BALL_SIZE.y }
				})
				.forEach(newPoint => {
					if (collision) {
						return;
					}

					// Check collision with borders
					if (!this.isInRect(newPoint, { x: 0, y: 0 }, Game.ARENA_SIZE)) {
						this.bounce(direction);
						collision = true;
					}

					// Check collision with paddle
					if (this.isInRect(newPoint, this.getPaddlePosition(), Game.PADDLE_SIZE)) {
						this.handlePaddleCollision(direction);
						collision = true;
					}

					// Check collision with block
					this.blocks.forEach(b => {
						if (this.isInRect(newPoint, b, Game.BLOCK_SIZE)) {
							this.handleBlockCollision(b, direction);
							collision = true;
						}
					});
				});

			if (!collision) {
				this.ball = newBallPosition;
			}
		});
	}

	handlePaddleCollision(direction: number): void {
		this.bounce(direction);
		// We change the angle based on the point of collision
		// range is in [-1, 1]
		let range = ((this.ball.x - this.paddleX) / Game.PADDLE_SIZE.x) - 0.5;
		let amplitude = Math.PI / 2;
		// (-...) because the coordinate system is upside down (y axis is inverted)
		this.ballAngle = (-Math.PI / 2) + range * amplitude;

		this.callModules(m => m.onBallPaddleCollision());
	}

	handleBlockCollision(block: Point, direction: number): void {
		// Remove the block
		this.blocks.splice(this.blocks.indexOf(block), 1);
		// Redirect the ball
		let ballAngleCollision = this.ballAngle;
		this.bounce(direction);

		if (this.blocks.length == 0) {
			this.reset();
		}

		this.callModules(m => m.onBlockBreak(block, ballAngleCollision));
	}

	bounce(direction: number): void {
		if (direction == 0) {
			this.ballAngle = -(this.ballAngle - Math.PI / 2) + Math.PI / 2;
		} else {
			this.ballAngle = -this.ballAngle;
		}
	}

	getPaddlePosition(): Point {
		return { x: this.paddleX, y: Game.ARENA_SIZE.y - Game.PADDLE_SIZE.y - Game.BORDER_SIZE };
	}

	getCanvasSize(): Point {
		return { x: Game.ARENA_SIZE.x + Game.BORDER_SIZE * 2, y: Game.ARENA_SIZE.y + Game.BORDER_SIZE };
	}

	isInRect(point: Point, posRect: Point, sizeRect: Point): boolean {
		return posRect.x <= point.x && point.x <= posRect.x + sizeRect.x
			&& posRect.y <= point.y && point.y <= posRect.y + sizeRect.y;
	}

	getModule<E extends Module>(moduleClass: typeof Module): E {
		let modules = this.modules.filter(m => m instanceof moduleClass);
		if (modules.length == 0) {
			throw new Error(`Couldn't find module of class ${moduleClass.name}`);
		} else if (modules.length > 1) {
			throw new Error(`Multiple modules of class ${moduleClass.name}`);
		}

		return modules[0] as E;
	}

	handleMouseMove(pos: Point) {
		this.paddleX = pos.x - Game.BORDER_SIZE - Game.PADDLE_SIZE.x / 2;
		this.paddleX = Math.max(0, Math.min(Game.ARENA_SIZE.x - Game.PADDLE_SIZE.x, this.paddleX));
	}

	callModules<E>(fn: (m: Module) => E, defaultValue: E = null): E {
		let value = this.modules.filter(m => m.enabled).reduce((p, c) => {
			let moduleValue = fn(c);
			return moduleValue != null ? moduleValue : p;
		}, null as E);

		return value != null ? value : defaultValue;
	}

	/**
	 * Drawing methods
	 */
	draw(ctx: CanvasRenderingContext2D, delta: number): void {
		// Draw Background
		let canvasSize = this.getCanvasSize();
		let backgroundColor = this.callModules(m => m.getBackgroundColor(), BLACK);
		this.rect(ctx, { x: 0, y: 0 }, canvasSize, backgroundColor);

		// Let modules draw something here
		this.callModules(m => m.drawAfterBackground(ctx, delta));
		this.callModules(m => m.drawBeforeElements(ctx, delta));

		// Draw borders
		let borderColor = this.callModules((m) => m.getBorderColor(), WHITE);
		// We draw 10 pixels outside the screen to make sure the borders
		// stay white when the screen shake happens
		// Left one
		this.rect(ctx,
			{ x: -10, y: -10 },
			{ x: Game.BORDER_SIZE + 10, y: Game.ARENA_SIZE.y + 10 + Game.BORDER_SIZE },
			borderColor
		);
		// Top one
		this.rect(ctx,
			{ x: -10, y: -10 },
			{ x: Game.ARENA_SIZE.x + 20 + 2 * Game.BORDER_SIZE, y: Game.BORDER_SIZE + 10 },
			borderColor
		);
		// Right one
		this.rect(ctx,
			{ x: Game.ARENA_SIZE.x + Game.BORDER_SIZE, y: 0 - 10 },
			{ x: Game.BORDER_SIZE, y: Game.ARENA_SIZE.y + 20 + Game.BORDER_SIZE },
			borderColor
		);

		// Draw the paddle
		let paddleColor = this.callModules(m => m.getPaddleColor(), WHITE);
		let paddleScale = this.callModules(m => m.getPaddleScale(), 1);
		let paddlePos = this.getPaddlePosition();
		this.rect(ctx,
			{ x: paddlePos.x + Game.BORDER_SIZE, y: paddlePos.y + Game.BORDER_SIZE },
			Game.PADDLE_SIZE,
			paddleColor,
			paddleScale
		);

		// Draw the ball
		let ballColor = this.callModules((m) => m.getBallColor(), WHITE);
		let ballScale = this.callModules((m) => m.getBallScale(), 1);
		this.rect(ctx,
			{ x: Game.BORDER_SIZE + this.ball.x, y: Game.BORDER_SIZE + this.ball.y },
			Game.BALL_SIZE,
			ballColor,
			ballScale
		);

		// Draw blocks
		this.blocks.forEach(b => {
			let blockColor = this.callModules(m => m.getBlockColor(b), WHITE);
			let blockScale = this.callModules(m => m.getBlockScale(b), 1);
			this.rect(
				ctx, {
					x: b.x + Game.BORDER_SIZE,
					y: b.y + Game.BORDER_SIZE
				},
				Game.BLOCK_SIZE, blockColor, blockScale
			);
		});
	}

	rect(
		ctx: CanvasRenderingContext2D,
		pos: Point,
		size: Point,
		color: Color,
		scale: number = 1,
		alpha: number = 1
	) {
		// Take into account the scale
		let scaledPos = {
			x: pos.x - size.x * (scale - 1) / 2,
			y: pos.y - size.y * (scale - 1) / 2
		};
		let scaledSize = {
			x: size.x * scale,
			y: size.y * scale
		};

		// Draw the rect with adjusted position and size
		ctx.fillStyle = toHexString(color, alpha);
		ctx.fillRect(
			Math.round(scaledPos.x), Math.round(scaledPos.y),
			Math.round(scaledSize.x), Math.round(scaledSize.y)
		);
	}
}
