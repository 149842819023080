import React, { FunctionComponent } from 'react';
import Header from './header';
import Footer from './footer';
import "./layout.css";

import { rhythm } from '../utils/typography';
import useWindowDimensions from '../utils/useWindowDimensions';

interface LayoutProps {
    location;
    maxi?: boolean;
}

const Layout: FunctionComponent<LayoutProps> = ({ children, maxi }) => {
    const { width } = useWindowDimensions();
    const bigWidth = width > 768;
    const headerMaxi = maxi && bigWidth;

    return (
        <div
            className="container"
            style={{
                marginLeft: `auto`,
                marginRight: `auto`,
                maxWidth: rhythm(26),
                padding: bigWidth ? `${rhythm(1.5)} ${rhythm(1)}` : `${rhythm(1)} ${rhythm(0.5)}`,
            }}
        >
            <Header maxi={headerMaxi} />
            <main>{children}</main>
            <Footer />
        </div>
    );
};

export default Layout;
