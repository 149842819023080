import Module from './Module';
import Game from '../Game';
import { randomPoisson } from '../utils/random';
import { WHITE } from '../Color';
import { easeOut } from '../utils/easing';

interface Particle {
    origin: Point;
    angle: number;
    distance: number;
    timeElapsed: number;
    scale: number;
}

export default class BallParticlesModule extends Module {
    readonly MEDIAN_TIME_BALL_PARTICLE = 0.05;
    readonly PARTICLE_SIZE = { x: 6, y: 6 };
    readonly PARTICLE_DURATION = 0.8;
    readonly PARTICLE_DISTANCE_MIN = 10;
    readonly PARTICLE_DISTANCE_RANGE = 30;
    readonly PARTICLE_ANGLE = Math.PI / 2;
    readonly PARTICLE_SCALE_RANGE = 0.4;
    readonly PARTICLE_OPACITY_START = 0.3;

    readonly PADDLE_PARTICLE_COUNT = 30;
    readonly PADDLE_PARTICLE_ANGLE = Math.PI / 2;
    readonly PADDLE_PARTICLE_DISTANCE_MIN = 10;
    readonly PADDLE_PARTICLE_DISTANCE_MAX = 60;

    particles: Particle[] = [];
    timeSinceLastBallParticle: number = 0;

    getName(): string {
        return 'Ball Particles Effects';
    }

    onEnable(): void {
        this.particles = [];
    }

    onBallPaddleCollision(): void {
        // We generate a volley of particle
        for (let i = 0; i < this.PADDLE_PARTICLE_COUNT; i++) {
            let paddlePosition = this.game.getPaddlePosition();
            let origin = {
                x: paddlePosition.x + Math.random() * Game.PADDLE_SIZE.x,
                y: paddlePosition.y + Math.random() * Game.PADDLE_SIZE.y,
            };

            this.particles.push({
                origin,
                angle:
                    -Math.PI / 2 -
                    this.PADDLE_PARTICLE_ANGLE / 2 +
                    Math.random() * this.PADDLE_PARTICLE_ANGLE,
                distance:
                    this.PADDLE_PARTICLE_DISTANCE_MIN +
                    this.PADDLE_PARTICLE_DISTANCE_MAX * Math.random(),
                timeElapsed: 0,
                scale:
                    1 -
                    this.PARTICLE_SCALE_RANGE / 2 +
                    Math.random() * this.PARTICLE_SCALE_RANGE,
            });
        }
    }

    drawBeforeElements(ctx: CanvasRenderingContext2D, delta: number): void {
        // Generate particles
        this.timeSinceLastBallParticle += delta;
        if (this.timeSinceLastBallParticle > this.MEDIAN_TIME_BALL_PARTICLE) {
            this.timeSinceLastBallParticle = 0;

            this.particles.push({
                origin: this.game.ball,
                angle:
                    this.game.ballAngle +
                    Math.PI +
                    this.PARTICLE_ANGLE * (Math.random() - 0.5),
                timeElapsed: 0,
                distance:
                    this.PARTICLE_DISTANCE_MIN +
                    Math.random() * this.PARTICLE_DISTANCE_RANGE,
                scale: 1 + Math.random() * this.PARTICLE_SCALE_RANGE,
            });
        }

        // Draw particles
        this.particles.forEach(p => {
            p.timeElapsed += delta;

            // Should we remove this particle
            if (p.timeElapsed > this.PARTICLE_DURATION) {
                this.particles.splice(this.particles.indexOf(p), 1);
                return;
            }

            let advancement = p.timeElapsed / this.PARTICLE_DURATION;
            // Smoothing function
            advancement = easeOut(advancement);
            let distance = advancement * p.distance;
            let opacity =
                this.PARTICLE_OPACITY_START -
                advancement * this.PARTICLE_OPACITY_START;

            let particlePos = {
                x: Game.BORDER_SIZE + p.origin.x + Math.cos(p.angle) * distance,
                y: Game.BORDER_SIZE + p.origin.y + Math.sin(p.angle) * distance,
            };

            // Get the color of the ball
            let ballColor = this.game.callModules(m => m.getBallColor(), WHITE);
            this.game.rect(
                ctx,
                particlePos,
                this.PARTICLE_SIZE,
                ballColor,
                p.scale,
                opacity
            );
        });
    }
}
