export function random(seed: number, min: number, max: number): number {
    return Math.floor(randomFloat(seed, min, max));
}

export function randomFloat(seed: number, min: number, max: number) {
    max = max || 1;
    min = min || 0;
 
    seed = (seed * 9301 + 49297) % 233280;
    let rnd = seed / 233280;
 
    return min + rnd * (max - min);
}

export function randomPoisson(duration, medianTime: number): number {
    let uniform = Math.random();
    return 1 - Math.pow(2, -duration / medianTime);
}

function fade(x) {
	return 6 * Math.pow(x, 5) - 15 * Math.pow(x, 4) + 10 * Math.pow(x, 3);
}

export function perlinNoise(x: number, period: number,
    getGradient: (seed) => number = (seed) => randomFloat(seed, -1, 1)
): number{
    let cellX = Math.floor(x / period);
	let relativeX = (x - cellX * period) / period;

    relativeX = fade(relativeX);

    let seed = cellX;
    let leftGradient = getGradient(seed);
    let rightGradient = getGradient(seed + 1);

	let leftContribution = leftGradient * relativeX;
	let rightContribution = rightGradient * (relativeX - 1);

	let finalValue = leftContribution + rightContribution;
	
	return finalValue;
}