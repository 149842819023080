/**
 * Library methods, for all interactive applications
 * of the article
 */
let seed = 10;

function random() {
    seed = (seed * 9301 + 49297) % 233280;
    return seed / 233280;
}

function randomDirection() {
    let angle = random() * 2 * Math.PI;

    return {
        x: Math.cos(angle),
        y: Math.sin(angle),
    };
}

function getPerlinVectors(width, height, period) {
    let gridWidth = Math.ceil(width / period) + 1;
    let gridHeight = Math.ceil(height / period) + 1;

    let grid = [];
    for (let y = 0; y < gridHeight; y++) {
        grid.push([]);
        for (let x = 0; x < gridWidth; x++) {
            grid[y][x] = randomDirection();
        }
    }

    return grid;
}

function dot(x1, y1, x2, y2) {
    return x1 * x2 + y1 * y2;
}

function lerp(a, b, t) {
    return a * (1 - t) + b * t;
}

function fade(x) {
    return 6 * Math.pow(x, 5) - 15 * Math.pow(x, 4) + 10 * Math.pow(x, 3);
}

function getPerlinNoise(x, y, period, perlinVectors, smooth) {
    let cellX = Math.floor(x / period);
    let cellY = Math.floor(y / period);
    let relativeX = (x - cellX * period) / period;
    let relativeY = (y - cellY * period) / period;

    if (smooth) {
        relativeX = fade(relativeX);
        relativeY = fade(relativeY);
    }

    let topLeftGradient = perlinVectors[cellY][cellX];
    let topRightGradient = perlinVectors[cellY][cellX + 1];
    let bottomLeftGradient = perlinVectors[cellY + 1][cellX];
    let bottomRightGradient = perlinVectors[cellY + 1][cellX + 1];

    let topLeftContribution = dot(
        topLeftGradient.x,
        topLeftGradient.y,
        relativeX,
        relativeY
    );
    let topRightContribution = dot(
        topRightGradient.x,
        topRightGradient.y,
        relativeX - 1,
        relativeY
    );
    let bottomLeftContribution = dot(
        bottomLeftGradient.x,
        bottomLeftGradient.y,
        relativeX,
        relativeY - 1
    );
    let bottomRightContribution = dot(
        bottomRightGradient.x,
        bottomRightGradient.y,
        relativeX - 1,
        relativeY - 1
    );

    let topLerp = lerp(topLeftContribution, topRightContribution, relativeX);
    let bottomLerp = lerp(
        bottomLeftContribution,
        bottomRightContribution,
        relativeX
    );
    let finalValue = lerp(topLerp, bottomLerp, relativeY);

    return finalValue / (Math.sqrt(2) / 2);
}

function getPerlinNoiseGrid(width, height, period, smooth) {
    let perlinVectors = getPerlinVectors(width, height, period);

    let grid = [];
    for (let y = 0; y < height; y++) {
        grid.push([]);
        for (let x = 0; x < width; x++) {
            grid[y][x] = getPerlinNoise(x, y, period, perlinVectors, smooth);
        }
    }

    return { grid: grid, perlinVectors: perlinVectors };
}

export function getOctavedPerlinNoiseGrid(
    newSeed,
    width,
    height,
    startPeriod,
    octaves,
    attenuation,
    smoothed
) {
    seed = newSeed;

    let finalGrid = [];
    for (let y = 0; y < height; y++) {
        finalGrid.push([]);
        for (let x = 0; x < width; x++) {
            finalGrid[y][x] = 0;
        }
    }

    const allPerlinVectors = [];

    for (let octave = 0; octave < octaves; octave++) {
        let period = startPeriod * Math.pow(1 / 2, octave);
        let octaveAttenuation = Math.pow(attenuation, octave);

        let { grid, perlinVectors } = getPerlinNoiseGrid(
            width,
            height,
            period,
            smoothed
        );
        allPerlinVectors.push(perlinVectors);
        for (let y = 0; y < height; y++) {
            for (let x = 0; x < width; x++) {
                finalGrid[y][x] += grid[y][x] * octaveAttenuation;
            }
        }
    }

    let maxValue = 0;
    for (let octave = 0; octave < octaves; octave++) {
        maxValue += Math.pow(attenuation, octave);
    }

    for (let y = 0; y < height; y++) {
        for (let x = 0; x < width; x++) {
            finalGrid[y][x] = finalGrid[y][x] / maxValue;
        }
    }

    return { grid: finalGrid, perlinVectors: allPerlinVectors };
}

export function drawToCanvas(grid, canvas, period, perlinVectors) {
    let context = canvas.getContext('2d');

    let pixels = context.getImageData(0, 0, grid[0].length, grid.length);
    for (let y = 0; y < grid.length; y++) {
        for (let x = 0; x < grid[0].length; x++) {
            let value = grid[y][x];

            for (let d = 0; d < 3; d++) {
                pixels.data[(y * grid[0].length + x) * 4 + d] =
                    128 + 128 * value;
            }
            pixels.data[(y * grid[0].length + x) * 4 + 3] = 255;
        }
    }

    context.putImageData(pixels, 0, 0);

    if (perlinVectors) {
        for (let y = 0; y < perlinVectors.length; y++) {
            for (let x = 0; x < perlinVectors[0].length; x++) {
                let perlinVector = perlinVectors[y][x];

                // "+ 0.5" everywhere so that the lines of width of 1px
                // don't end up split between 2 pixels
                context.beginPath();
                context.moveTo(x * period + 0.5, y * period + 0.5);
                context.lineTo((x + 1) * period + 0.5, y * period + 0.5);
                context.lineTo((x + 1) * period + 0.5, (y + 1) * period + 0.5);
                context.lineTo(x * period + 0.5, (y + 1) * period + 0.5);
                context.closePath();

                context.strokeStyle = 'cyan';
                context.stroke();

                context.beginPath();
                context.moveTo(x * period + 0.5, y * period + 0.5);
                context.lineTo(
                    x * period + 0.5 + (perlinVector.x * period) / 2,
                    y * period + 0.5 + (perlinVector.y * period) / 2
                );
                context.closePath();

                context.strokeStyle = 'red';
                context.stroke();

                let posX = x * period + 0.5 + (perlinVector.x * period) / 2;
                let posY = y * period + 0.5 + (perlinVector.y * period) / 2;
                let angle = Math.atan2(perlinVector.y, perlinVector.x);
                angle += (150 * 2 * Math.PI) / 360;
                let sizeTriangleArrow = period / 5;

                context.beginPath();
                context.moveTo(posX, posY);
                for (let i = 0; i < 3; i++) {
                    posX += sizeTriangleArrow * Math.cos(angle);
                    posY += sizeTriangleArrow * Math.sin(angle);
                    context.lineTo(posX, posY);
                    angle += (120 * 2 * Math.PI) / 360;
                }
                context.closePath();

                context.fillStyle = 'red';
                context.fill();
            }
        }
    }
    context.beginPath();
}
