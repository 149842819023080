import * as React from 'react';
import Game from './Game';
import Module from './modules/Module';
import { observer } from 'mobx-react';

interface ModuleControlsProps {
    game: Game;
}

@observer
export default class ModuleControls extends React.Component<
    ModuleControlsProps,
    null
> {
    render() {
        return (
            <div>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginBottom: '8px',
                    }}
                >
                    <button
                        style={{ marginRight: 4 }}
                        onClick={() => this.enableAll()}
                    >
                        Enable all
                    </button>
                    <button
                        style={{ marginLeft: 4 }}
                        onClick={() => this.disableAll()}
                    >
                        Disable all
                    </button>
                </div>
                <div
                    style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        margin: '-4px',
                    }}
                >
                    {this.props.game.modules.map(m => (
                        <div
                            style={{
                                flexBasis: 200,
                                flexGrow: 1,
                            }}
                        >
                            <div
                                style={{
                                    opacity: m.canEnable() ? 1 : 0.2,
                                    padding: '4px',
                                    border: '1px solid currentColor',
                                    margin: '4px',
                                    transition: 'opacity 0.3s',
                                }}
                            >
                                <label>
                                    <input
                                        type='checkbox'
                                        disabled={!m.canEnable()}
                                        onChange={() =>
                                            this.handleCheckboxChange(m)
                                        }
                                        checked={m.enabled}
                                    />
                                    {m.getName()}
                                </label>
                                <div
                                    style={{
                                        opacity: m.enabled ? 1 : 0.2,
                                        transition: 'opacity 0.3s',
                                    }}
                                >
                                    {m.render()}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        );
    }

    componentDidMount() {
        window.addEventListener('keypress', e => {
            if (e.key == 'e') {
                if (this.props.game.modules[0].enabled) {
                    this.disableAll();
                } else {
                    this.enableAll();
                }
            }
        });
    }

    enableAll(): void {
        // Assuming that modules are ordered in dependency order
        this.props.game.modules
            .filter(m => !m.enabled)
            .forEach(m => {
                m.toggleEnable();
            });
    }

    disableAll(): void {
        // Assuming that modules are ordered in dependency order
        this.props.game.modules
            .slice()
            .reverse()
            .forEach(m => {
                if (m.enabled) {
                    m.toggleEnable();
                }
            });
    }

    handleCheckboxChange(m: Module): void {
        m.toggleEnable();
    }
}
