import { graphql, Link, useStaticQuery } from 'gatsby';
import { rhythm, scale } from '../utils/typography';
import React, { FunctionComponent } from 'react';
import * as headerClasses from './header.module.css';
import { StaticImage } from 'gatsby-plugin-image';

interface HeaderProps {
    maxi?: boolean;
}

const Header: FunctionComponent<HeaderProps> = ({ maxi }) => {
    const data = useStaticQuery(graphql`
        query HeaderQuery {
            site {
                siteMetadata {
                    title
                    author
                    social {
                        twitter
                    }
                }
            }
        }
    `);
    const { title, author } = data.site.siteMetadata;

    const HTitle = maxi ? `h1` : `h3`;

    return (
        <header style={{ display: 'flex', alignItems: 'center' }}>
            <div>
                <HTitle
                    style={{
                        ...scale(maxi ? 1.3 : 0.8),
                        marginBottom: maxi ? rhythm(0.5) : rhythm(0.3),
                        marginTop: 0,
                    }}
                >
                    <Link
                        style={{
                            boxShadow: `none`,
                            textDecoration: `none`,
                        }}
                        to={`/`}
                    >
                        {title}
                    </Link>
                </HTitle>
                <nav className={headerClasses.headerNav}>
                    <ul>
                        <li>
                            <Link to='/'>Blog</Link>
                        </li>
                        <li>
                            <Link to='/projects/'>Projects</Link>
                        </li>
                    </ul>
                </nav>
            </div>
        </header>
    );
};

export default Header;
